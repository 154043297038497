import { vehicleToCameraMapping } from "@/globalConstants";
import axios from "axios";
import { useEffect, useRef, useState } from "react";



const IpPTZCamera = ({cameraSerialNo}: any) => {
  const [accessToken, setAccessToken] = useState(null);
  const playerRef = useRef(null);

  console.log('serial no', cameraSerialNo);
  

  const cameraConfigurationApiUrl =
    'https://isgpopen.ezvizlife.com/api/lapp/token/get?appKey=bf57258481c84ff1844955d98d9820c4&appSecret=db20b30b368e4f22825b2cfb7ee1342f';

  const getCameraConfigurations = async () => {
    try {
      const { data } = await axios.post(cameraConfigurationApiUrl);
      console.log('-------->',data);
      
      setAccessToken(data?.data?.accessToken);
    } catch (err) {
      console.error('Failed to fetch camera configurations:', err);
      setAccessToken(null);
    }
  };


  const loadScript = (src: any, callback: any) => {
    const script = document.createElement('script');
    script.src = src;
    script.onload = callback;
    script.onerror = () => console.error(`Failed to load script: ${src}`);
    document.body.appendChild(script);
  };

  useEffect(() => {
    getCameraConfigurations();
  }, []);

  useEffect(() => {
    if (accessToken) {
      // Initialize EZUIKitPlayer after getting the access token
      loadScript('/ezuikit.js', () => {
        console.log('EZUIKit script loaded successfully');
        const player = new EZUIKit.EZUIKitPlayer({
            id: "video-container", // 视频容器ID
            accessToken: accessToken,
            url: `ezopen://open.ezviz.com/${cameraSerialNo}/1.hd.live`,
            width: 600,
            height: 400,
            autoplay: true,
            template: "pcLive",
            language: "en",
            env: {
              domain: "https://isgpopen.ezvizlife.com",
            },
            handleError: (err) => {
              console.error("Error: ====>", err);
              if (err.type === "handleRunTimeInfoError" && err.data.nErrorCode === 5) {
                console.error(err);
              }
            },
          })
          playerRef.current = player;
      });

      // Save the player instance in ref (optional)

      return () => {
        // Clean up the player on component unmount
        playerRef.current = null;
      };
    }
  }, [accessToken]);

  return (
    <div>
      {accessToken ? (
        <div id="video-container" ref={playerRef} style={{ width: '600px', height: '400px' }}></div>
      ) : (
        <p>Loading camera configurations...</p>
      )}
    </div>
  );
};

export default IpPTZCamera;
