

export const analyticsDashboardAccessSources = [
    'DEMO00009',
    'HYDH133B001',
    'BLRH002B002',
    'DLHH002B001',
    'LCKH003B001',
];

export const caseCreationAccessSources = [
    'DEMO00009',
    'HYDH133B001',
    'BLRH002B002',
    'DLHH002B001',
    'LCKH003B001'
];

export const ptzCameraEnabledVehicles = [
    'TS09UD2294',
    "DEMO09UD89T"
]

export const vehicleToCameraMapping = {
    "TS09UD2294": "BD4707880",
    "DEMO09UD99T": "BD0620485",
    "DEMO09UD89T": "BE4966239",

}

export const syncXEnabledVehicles = [
    "TEMP2066",
    "DEMO09UD89T",
]